import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import Quote from "../components/projectquote";
import GalleryItem from "../components/galleryitem";
import ShopifyCollection from "../components/shopifycollection";
import Footer from "../components/footer";
import Header from "../components/projectheader";

import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

import styleProject from "../components/about.module.css";

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.markdownRemark.frontmatter.name + " - Jobs at No Goblin!"}</title>
        <link rel="canonical" href="https://nogoblin.com" />
        <meta property="og:title" content={data.markdownRemark.frontmatter.name + " - Jobs at No Goblin!"} />
        <meta property="og:description" content="We're hiring! No Goblin has made unique games like Roundabout and 100ft Robot Golf, and we’re now looking to grow and build a unique, diverse team for No Goblin Game 3 and beyond." />
        <meta property="og:url" content="https://nogoblin.com" />
        <meta property="og:image" content="https://nogoblin.com/ms-icon-310x310.png" />
        <meta property="og:site_name" content="No Goblin" />
        <meta property="og:type" content="object" />
      </Helmet>
      <div className={styleProject.fakeBackground}>

      <center>
          <div className={styleProject.downstreamContainer}>
            <div className={styleProject.capsule + " " + styleProject.faqBody}>
              <div className={styleProject.faqHeader}>
                <h1>Come work with us!</h1>
              </div>

    

              
                { (data.markdownRemark.frontmatter.status == "pending") && 

              <div><b>Applications for this role are closed.<br/><br/>Our thanks go out to everyone who applied for the {data.markdownRemark.frontmatter.name} role! We're going through all your great applications and will make a decision in the next few weeks. Stay tuned!<br/><br/>For reference, here's the original job posting:</b></div>
              }

              <div
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />

            </div>
          </div>
        <Footer />
      </center>
      </div>
    </Layout>
  );
};

// The post template's GraphQL query. Notice the “id”
// variable which is passed in. We set this on the page
// context in gatsby-node.js.
//
// All GraphQL queries in Gatsby are run at build-time and
// loaded as plain JSON files so have minimal client cost.
export const pageQuery = graphql`
  query JobReqQuery($mdPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $mdPath } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        name
        status
      }
    }
  }
`;
