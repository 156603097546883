import React from "react";
import styleSelf from "./galleryitem.module.css";
import Img from "gatsby-image"
import { Link } from "gatsby"

export default ({ itemData }) => { 
    return (
    <div className={styleSelf.galleryItem}>
        <a href={itemData.image.childImageSharp.full.src}><Img fluid={itemData.image.childImageSharp.thumb} className={styleSelf.galleryThumb} /></a>
    </div>
  )}

