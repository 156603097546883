import React from "react";
import styleSelf from "./projectquote.module.css";

export default ({ quoteData }) => (
    <div className={styleSelf.contentContainer}>
       <span className={styleSelf.quote}>"{quoteData.quote}"</span><br/>
       <span className={styleSelf.author}>{quoteData.author}</span>
    </div>
  )

