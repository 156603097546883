import React from "react";
import styleSelf from "./projectheader.module.css";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";

export default ({ twitter }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "images/frontpage-logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        twitterImg: file(relativePath: { eq: "images/twitter.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 32, height: 32) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styleSelf.headerContainer}>
        <Link to="/">
          <Img fixed={data.logo.childImageSharp.fixed} />
        </Link>
        <span className={styleSelf.headerContent}>
          <a href={"http://twitter.com/" + twitter}>@{twitter}</a>
        </span>
      </div>
    )}
  />
);
